import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import video1 from "../../images/hme-2/video-1.png"
import video2 from "../../images/hme-2/video-2.png"
import video3 from "../../images/hme-2/video-3.png"
import graphic3 from "../../images/hmeLogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-item p {
    max-width: 280px;
    margin: 0 auto;
  }
  .grid-item p.number {
    font-size: 5rem;
    line-height: 4.5rem;
    color: #3ea5c3;
    margin-bottom: 1rem;
  }
  .key-persona ul li {
    margin-bottom: 0.5rem;
  }
  .key-persona {
    display: flex;
    margin: 2rem 0;
  }
  .form-factor {
    display: flex;
    margin: 2rem 0;
  }

  .form-factor p {
    margin-right: 3rem;
  }
  .form-factor div {
    width: 50%;
  }

  .persona {
    aspect-ratio: 1 / 1;
    max-width: 250px;
    margin-right: 3rem;
  }
  .footnote {
    font-size: 0.7rem;
    line-height: 0.6rem;
    margin-bottom: 3rem;
  }
  .videos {
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin-bottom: 3rem;
  }
  a.video img {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 800px) {
    .form-factor div {
      width: 100%;
    }
    .key-persona,
    .form-factor {
      display: flex;
      margin: 2rem 0;
      flex-direction: column;
    }
    .persona {
      aspect-ratio: 2 / 1;
      max-width: none;
      margin-right: 0;
      margin-bottom: 2rem !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .grid3 {
      grid-template-columns: repeat(1, 1fr);
    }
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .videos {
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      margin-bottom: 3rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/hme-2/hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ expereince design</p>
          </div>
          <div className="desc">
            <h2>Gamification in Retail</h2>
            <p>
              helping quick-serve restaurants bring more fun into service roles
              while increasing performance efficiencies
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Discovery</li>
          <li>Journeymap</li>
          <li>Work flow</li>
          <li>Wireframe</li>
          <li>Visual Design</li>
          <li>Prototype</li>
        </ul>
      </div>

      <div className="highlights">
        <h5>Desired Outcomes:</h5>
        <ul>
          <li>
            Promote engagement and fun with crew members across stores and
            franchises
          </li>
          <li>
            Increase overall store performance through crew engagement and
            incentives
          </li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Assumptions:</h5>
        <ul>
          <li>The desire to achieve drives productivity</li>
          <li>
            Game-based engagement makes work more enjoyable through rewards,
            gratification, motivation, & feedback
          </li>
        </ul>
      </div>
      <div className="grid grid3">
        <div className="grid-item">
          <p className="number">90%</p>
          <p>of employees are more productive when they use gamification.</p>
        </div>
        <div className="grid-item">
          <p className="number">48%</p>
          <p>
            increase in employee engagement with game-based motivation.{" "}
            <sup>1</sup>
          </p>
        </div>
        <div className="grid-item">
          <p className="number">70%</p>
          <p>
            of Forbes Global 2000 companies use gamification to boost retention,
            engagement, and revenues.<sup>2</sup>
          </p>
        </div>
      </div>
      <div className="footnote">
        <p>
          1{" "}
          <a href="https://medium.com/swlh/how-gamification-in-the-workplace-impacts-employee-productivity-a4e8add048e6">
            Medium
          </a>
        </p>
        <p>
          2{" "}
          <a href="https://www.qualtrics.com/blog/employee-experience-stats/">
            Qualdratics
          </a>
        </p>
      </div>

      <div className="key-areas">
        <h2>PROJECT ENGAGEMENT / 4 months</h2>
        <div className="areas areas2">
          <div className="area">
            <h4>CONSULTING TEAM (project202)</h4>
            <ul>
              <li>Account Exective</li>
              <li>Project Manager</li>
              <li>Experience Design Director (Ned Cannon)</li>
              <li>Product Designer</li>
              <li>Product Designer</li>
            </ul>
          </div>

          <div className="area">
            <h4>CLIENT STAKEHOLDERS (HME)</h4>
            <ul>
              <li>General Manager, HME Drive-Thru Division</li>
              <li>Program Manager</li>
              <li>Product Manager</li>
              <li>Development Team</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="users">
        <h5>Key Personas:</h5>
        <div className="key-persona">
          <StaticImage
            src="../../images/hme-2/Persona_1.jpg"
            alt="Project"
            placeholder="dominantColor"
            className="persona"
          />
          <p>
            <h4>CREW MEMBER (rotating roles / teams)</h4>
            <p>“I’m hoping to enjoy the next 4 hours.”</p>
            <ul>
              <li>52% women, 48% men, average age 27yo</li>
              <li>
                Large % of crews composed of high school PT workers covering
                variable roles
              </li>
              <li>
                Typically a “first job”, could work in multiple roles and
                locations in same city/region
              </li>
              <li>
                Responsible for predictable, quality service at efficient work
                rate
              </li>
            </ul>
          </p>
        </div>
        <div className="key-persona">
          <StaticImage
            src="../../images/hme-2/Persona_2.jpg"
            alt="Project"
            placeholder="dominantColor"
            className="persona"
          />
          <p>
            <h4>LOCATION MANAGER</h4>
            <p>
              “The more cars we serve, the better our daily numbers and my
              paycheck looks .”
            </p>
            <ul>
              <li>46% women, 54% men, average age 38yo</li>
              <li>Career QSR Managers can manage multiple locations</li>
              <li>
                Responsible for staffing, shift management, overall quality of
                service and productivity
              </li>
              <li>
                Could work in multiple locations in same region, typically under
                same franchisee
              </li>
            </ul>
          </p>
        </div>
        <div className="key-persona">
          <StaticImage
            src="../../images/hme-2/Persona_4.jpg"
            alt="Project"
            placeholder="dominantColor"
            className="persona"
          />
          <p>
            <h4>LOCATION / FRANCHISE OWNER</h4>
            <p>“I bought these restaurant fanchises to make money.”</p>
            <ul>
              <li>34% women, 66% men, average age 44yo</li>
              <li>
                QSR Franchise Owners typically own and operate multiple
                locationS
              </li>
              <li>
                Responsible for overall operational management and profitability
                of location[s]
              </li>
              <li>
                Responsible for predictable, quality service at efficient work
                rate
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className="users">
        <h5>ENVIRONMENT / FORM FACTOR</h5>
        <div className="form-factor">
          <div>
            <h4>CREW MEMBER (rotating roles / teams)</h4>
            <p>
              Crew workers on feet and moving from station to station in busy
              fast food environment
            </p>
            <p>
              No access to computer terminal with keyboard, mouse or interactive
              display
            </p>
            <p>Crew is permitted mobile phones during breaks</p>
            <p>
              Visual display limited to standard resolution screens located
              above head and out of view from store guests{" "}
            </p>
          </div>

          <StaticImage
            src="../../images/hme-2/form-factor.png"
            alt="Project"
            placeholder="dominantColor"
            className="ff-img"
          />
        </div>
      </div>
      <div className="screens">
        <div className="project-label">
          <p>Game Theory Research + Feature Development</p>
        </div>
        <StaticImage
          src="../../images/hme-2/hme-gamify-1.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />

        <div className="project-label">
          <p>Design Execution</p>
        </div>
        <StaticImage
          src="../../images/hme-2/hme-gamify-2.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/hme-2/hme-gamify-3.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
      </div>
      <div className="project-label">
        <p>Overview Videos</p>
      </div>
      <div className="grid videos">
        <div className="grid-item">
          <a
            className="video"
            href="https://www.youtube.com/watch?v=w0h9YvTuHeU"
            target="_blank"
          >
            <img alt="video" src={video1} />
          </a>
          <p>Achieve Goals, Earn Awards</p>
        </div>
        <div className="grid-item">
          <a
            className="video"
            href="https://www.youtube.com/watch?v=ieGfjBC1CP4"
            target="_blank"
          >
            <img alt="video" src={video2} />
          </a>
          <p>Drive-thru Smack Talk</p>
        </div>

        <div className="grid-item">
          <a
            className="video"
            href="https://www.youtube.com/watch?v=2WOUHW2hHSk&t=35s"
            target="_blank"
          >
            <img alt="video" src={video3} />
          </a>
          <p>Real-Time Feedback</p>
        </div>
      </div>

      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/jaxjox">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
